<template>
 <form
  @submit.prevent="isEdit && data?.has_user ? editContact(data.id) : postContact()"
  method="POST"
  class="divide-y divide-gray-200"
 >
  <div>
   <div class="grid grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-6">
    <div class="sm:col-span-1">
     <label for="civility" class="block text-sm font-medium leading-6 text-gray-900">
      {{ $t("civility") }}
     </label>
     <select
      required
      v-model="form.civility"
      id="civility"
      name="civility"
      class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
     >
      <option
       v-for="(civility, idx) in civilities"
       :key="idx"
       :value="getKeyByValue(civilities, civility)"
      >
       {{ $t(civility) }}
      </option>
     </select>
    </div>
    <div class="sm:col-span-2">
     <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">
      {{ $t("firstName") }}
     </label>
     <input
      v-model="form.firstName"
      type="text"
      name="first-name"
      id="first-name"
      autocomplete="given-name"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
     />
    </div>

    <div class="sm:col-span-2">
     <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">
      {{ $t("lastName") }}
     </label>
     <input
      required
      v-model="form.lastName"
      type="text"
      name="last-name"
      id="last-name"
      autocomplete="family-name"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
     />
    </div>

    <div class="sm:col-span-4">
     <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
      {{ $t("emailAddress") }}
     </label>
     <input
      required
      v-model="form.emailAddress"
      id="email"
      name="email"
      type="email"
      autocomplete="email"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
     />
    </div>
    <div class="sm:col-span-3">
     <label for="phone-number" class="block text-sm font-medium leading-6 text-gray-900">
      {{ $t("landlinePhone") }}
     </label>
     <input
      v-model="form.landlinePhone"
      type="text"
      name="phone-number"
      id="phone-number"
      autocomplete="phone"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
     />
    </div>

    <div class="sm:col-span-3">
     <label for="mobile-number" class="block text-sm font-medium leading-6 text-gray-900">
      {{ $t("mobilePhone") }}
     </label>
     <input
      v-model="form.mobilePhone"
      type="text"
      name="mobile-number"
      id="mobile-number"
      autocomplete="phone"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
     />
    </div>

    <div class="sm:col-span-6">
      <div class="flex items-center h-6">
        <input
          v-model="form.active"
          type="checkbox"
          name="active"
          id="active"
          class="w-4 h-4 text-green-600 border-gray-300 rounded focus:ring-green-600"
        />
        <label
          for="active"
          class="ml-2 text-sm text-gray-900"
        >
        {{ $t("active") }}
        </label>
      </div>
    </div>

    <div v-if="isEdit && data?.has_user" class="grid grid-cols-12 sm:col-span-6">
     <div class="col-span-12 pb-2 border-b border-gray-200">
      <div class="flex flex-wrap items-baseline -mt-2 -ml-2">
       <h3 class="mt-2 ml-2 text-base font-semibold leading-6 text-gray-900">Permissions</h3>
       <p class="mt-1 ml-1 text-sm text-gray-500 truncate">
        (liste des permissions de l'utilisateur à l'espace client)
       </p>
      </div>
     </div>
    <fieldset
      v-for="(group, index) in groupedPermissions"
      :key="index"
      class="col-span-2 mt-4"
      v-if="form.active"
      v-show="
      group.legend !== 'customer' && group.legend !== 'customers' && group.legend !== 'training'
      "
    >
      <legend class="text-base font-semibold leading-6 text-gray-900">
      {{ $t(group.legend) }}
      </legend>
      <div v-for="permission in group.permissions" :key="permission">
      <div class="relative flex items-start">
        <div class="flex items-center h-6">
        <input
          @change="updateSelectedValues(group, permission, $event)"
          :aria-describedby="`${permission}-description`"
          :name="permission"
          :id="`${permission}`"
          :value="permission"
          :checked="form.permissions.includes(`${permission}`)"
          :disabled="group.selectedWildcard && permission !== group.wildcardPermission"
          type="checkbox"
          class="w-4 h-4 text-green-600 border-gray-300 rounded focus:ring-green-600"
        />
        <label
          :for="`${permission}`"
          :class="[
          group.selectedWildcard && permission !== group.wildcardPermission
            ? 'text-gray-400 italic'
            : 'text-gray-700',
          'ml-2 text-sm',
          ]"
        >
          {{ $t(`permission.${permission.split(".")[1]}`) }}
        </label>
        </div>
      </div>
      </div>
    </fieldset>
    <div
      v-else
      class="col-span-12 pt-8 pb-2 text-sm text-center text-gray-800"
    >
      Un utilisateur inactif ne possède aucune permissions sur le compte.
    </div>
    </div>
   </div>
  </div>
  <div class="flex items-center justify-end pt-4 mt-6 gap-x-6">
   <div class="flex justify-end">
    <button
     type="button"
     @click="$emit('closeForm')"
     class="px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
    >
     {{ $t("ticket.cancel") }}
    </button>
    <button
     type="submit"
     class="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded shadow-sm body__table--header"
    >
     {{ $t("ticket.send") }}
    </button>
   </div>
  </div>
 </form>
</template>

<script>
import axios from "axios";
import ConfirmationDialog from "./ConfirmationDialog.vue";

const account = localStorage.getItem("account");

export default {
 props: {
  data: {
   type: Object,
   required: false,
  },
  permissions: {
   type: Array,
   required: false,
  },
  isEdit: {
   type: Boolean,
   required: true,
  },
 },
 components: {
  ConfirmationDialog,
 },
 data() {
  return {
   account,
   civilities: {},
   form: {
    active: null,
    civility: "3",
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobilePhone: null,
    landlinePhone: null,
    permissions: [],
   },
  };
 },
 computed: {
  groupedPermissions() {
   const groups = {};
   this.permissions.forEach((permission) => {
    const [group, right] = permission.split(".");
    if (!groups[group]) {
     groups[group] = {
      legend: group,
      permissions: [],
      wildcardPermission: null,
      selectedWildcard: false, // new property to track if the wildcard checkbox has been selected
     };
    }
    groups[group].permissions.push(permission);
    if (right === "*") {
     groups[group].wildcardPermission = permission;
    }
   });

   const groupOrder = ["ticketing"];

   // Define the desired order of permissions within each group
   const permissionOrder = ["*", "view", "view-own", "edit", "edit-own", "create", "delete"];

   const sortedGroups = Object.values(groups).sort((a, b) => {
    const groupAIndex = groupOrder.indexOf(a.legend);
    const groupBIndex = groupOrder.indexOf(b.legend);
    return groupAIndex - groupBIndex;
   });

   sortedGroups.forEach((group) => {
    group.permissions.sort((a, b) => {
     const aIndex = permissionOrder.indexOf(a.split(".")[1]);
     const bIndex = permissionOrder.indexOf(b.split(".")[1]);

     // If a permission is not in the permissionOrder, move it to the bottom
     if (aIndex === -1) {
      return 1;
     } else if (bIndex === -1) {
      return -1;
     }

     return aIndex - bIndex;
    });
   });

   return sortedGroups;
  },
 },
 methods: {
  async postContact() {
   delete this.form.permissions;
   delete this.form.roles;
   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie("API")}/api/v1/users?customerAccount=${this.account}`,
    data: this.form,
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     const successMsg = this.$t("elementCreatedSuccessfully", { element: this.$t("userMessage") });
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", successMsg);
     this.$router.push(`/contact/${res.data.id}`);
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  editContact(id) {
   delete this.form.myClient;
   delete this.form.source;
   delete this.form.has_user;
   delete this.form.roles;
   const data = JSON.stringify(this.form);
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/users/${id}?customerAccount=${this.account}`,
    data: data,
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     const successMsg = this.$t("elementUpdatedSuccessfully", { element: this.$t("userMessage") });
     this.$emit("closeForm");
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", successMsg);
    })
    .catch((err) => {
     console.error(err.response);

     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", err.response.data.response);
    });
  },
  async getCivilities() {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/civilities`, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    });
    this.civilities = res.data;

    this.$emit("setLoading", false);
   } catch (error) {
    console.error(error.response);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  getKeyByValue(object, value) {
   for (let key in object) {
    if (object[key] === value) {
     return key;
    }
   }
  },
  assignObject(object) {
   this.form = JSON.parse(JSON.stringify(object));
   delete this.form.id; // or use => delete test['blue']
   delete this.form.contactable_type;
   delete this.form.contactable_id;
   delete this.form.created_at;
   delete this.form.updated_at;

   this.form.active = object.active == 1;

   if (object.permissions) {
    this.form.permissions = [...object.permissions];
    this.groupedPermissions.forEach((group) => {
     if (group.wildcardPermission && this.form.permissions.includes(group.wildcardPermission)) {
      group.selectedWildcard = true;
     }
    });
   } else {
    this.form.permissions = ["", "", ""];
   }
  },

  updateSelectedValues(group, permission, event) {
   const value = event.target.value;
   if (event.target.checked) {
    this.form.permissions.push(value);
    if (permission === group.wildcardPermission) {
     group.selectedWildcard = true;
    }
   } else {
    const index = this.form.permissions.indexOf(value);
    if (index !== -1) {
     this.form.permissions.splice(index, 1);
     if (permission === group.wildcardPermission) {
      group.selectedWildcard = false;
     }
    }
   }
   // Uncheck all other checkboxes in the group if the wildcard checkbox has been selected
   if (group.selectedWildcard) {
    for (let index = 0; index < group.permissions.length; index++) {
     const element = group.permissions[index];

     const idx = this.form.permissions.indexOf(element);
     if (idx !== -1 && element !== group.wildcardPermission) {
      this.form.permissions.splice(idx, 1);
     }
    }
   }
  },
 },

 mounted() {
  this.getCivilities();
  if (this.data) this.assignObject(this.data);
 },
};
</script>

<style></style>
